import React, { useState, useEffect } from 'react';
import ReferenceItem from './ReferenceItem';

const ReferenceList = () => {
  const [references, setReferences] = useState([]);

  useEffect(() => {
    const fetchReferences = async () => {
      try {
        const response = await fetch('/references.json');
        const data = await response.json();
        setReferences(data);
      } catch (error) {
        console.error('Error fetching reviews items:', error);
      }
    };

    fetchReferences();
  }, []);

  return (
    <div className="flex-row">
      {references.map((reference, index) => (
        <ReferenceItem key={index} review={reference} />
      ))}
    </div>
  );
};

export default ReferenceList;