import React from "react";
import MapEmbed from "../components/MapEmbed";
import { Helmet } from "react-helmet-async";
 
const Contact = () => {
    return (
        <>
            <Helmet>
                <title>Team Ounamo - Yhteystiedot</title>
            </Helmet>
            <section>
                <h2>
                    Yhteystiedot
                </h2>
                <strong>Team Ounamo Tmi</strong>
                <p>Y: 3287725-3</p>
                <p>Kai Ounamo</p>
                <p>041 5389544</p>
                <p><a href="mailto:teamounamo@gmail.com">teamounamo@gmail.com</a></p>
                <br></br>
                <strong>Lounasravintola Nokkis</strong>
                <p>Metrovarikonkuja 6, 00880 Helsinki</p>
                <MapEmbed />
            </section>
        </>
    );
};
 
export default Contact;