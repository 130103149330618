import React from 'react';

const MapEmbed = () => {
  return (
    <div style={{ maxWidth: '800px', height: '500px' }}>
        <iframe
            title="Nokkis on Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1982.1017455500237!2d25.06327057801322!3d60.212147240044324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4692097ff0f385d7%3A0x19e42661aff04002!2sRavintola%20Nokkis!5e0!3m2!1sfi!2sfi!4v1725025108837!5m2!1sfi!2sfi"
            width="100%"
            height="300"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade">
        </iframe>
    </div>
  );
};

export default MapEmbed;