import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { inject } from '@vercel/analytics';
import './App.css';
import About from './pages/about';
import Menu from './pages/menu';
import Contact from './pages/contact';
import Home from './pages/home';

function App() {
  inject()
  return (
    <Router>
      <div className="app-container">
        <header>
          <h1 className='header-h1'><a href="/" className='text-link'>Team Ounamo Tmi</a></h1>
        </header>

        <nav>
          <Link to="/">Etusivu</Link>
          <Link to="/nokkis">Nokkis</Link>
          <Link to="/about">Meistä</Link>
          <Link to="/contact">Yhteystiedot</Link>
        </nav>
        <main>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/nokkis" element={<Menu />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>

        <footer>
        <p>Löydät meidät myös somesta:</p>
        <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=100086763657286" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} className='faicon' />
            </a>
            <a href="https://www.instagram.com/ravintola_nokkis" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} className='faicon' />
            </a>
        </div>
          <p>&copy; 2023 Team Ounamo Tmi. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;