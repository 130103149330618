import React from 'react';

const ReferenceItem = ({ review }) => {
  return (
    <div className="reference-item">
      <p>{review.text}</p>
      <hr></hr>
      <h3>{review.from}</h3>
    </div>
  );
};

export default ReferenceItem;