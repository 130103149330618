import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const ExpandableList = ({ items }) => {
  const [expandedItem, setExpandedItem] = useState(0);

  const toggleItem = (index) => {
    if (expandedItem === index) {
      setExpandedItem(null); // Collapse the item if it's already expanded
    } else {
      setExpandedItem(index);
    }
  };
  return (
    <>
      <Helmet>
        <title>Ravintola Nokkis</title>
      </Helmet>
      <div>
      <h2>Nokkiksen ruokalistalla tällä viikolla:</h2>
      <div>
        {Object.entries(items).map(([day, menu], index) => (
          <div onClick={() => toggleItem(index)} key={index} className='menu-item'>
            <h3 style={{ 'text-decoration': 'underline'  }}>{day}</h3>
            {expandedItem === index ? <i className='arrow up'></i> : <i className='arrow down'></i>}
            {expandedItem === index && <MenuItem key={index} menu={menu} />}
          </div>
        ))}
      </div>
      </div>
    </>
  );
};

const MenuItem = ({ day, menu }) => (
  <div>
    <h3>{day}</h3>
      {menu.map((menu, index) => (
        <p key={index}>{menu}</p>
      ))}
  </div>
);
 
const Menu = () => {
    const [menuItems, setMenuItems] = useState([]);
  
    useEffect(() => {
      const fetchMenuItems = async () => {
        try {
          const response = await fetch('/menu.json');
          const data = await response.json();
          setMenuItems(data);
        } catch (error) {
          console.error('Error fetching menu items:', error);
        }
      };
  
      fetchMenuItems();
    }, []);
    return (
        <section className='flex-row'>
          <div className='flex-column'>
            <h2>Tervetuloa lounasravintola Nokkikseen!</h2>
            
            <strong>Aamupala arkisin klo. 07.30-09.00</strong>
            <strong>Lounas arkisin klo. 10.30-13.00</strong>

            <p>Koko lounasmenu 11,80 (*minilounas 8,50)</p>

            <div className='flex-row'>
              <ExpandableList items={menuItems} />
            </div>
          </div>
        </section>
    );
};
 
export default Menu;
